<template>
  <div class="main finger border-bg">
    <div class="border-tit">当前位置 > 成绩查询 > 《考试名称》成绩查询</div>
    <div class="result-tit">《考试名称》成绩查询结果</div>
    <table>
      <tr class="titColor">
        <th colspan="4">考 生 信 息</th>
      </tr>
      <tr>
        <td class="width">姓 名：</td>
        <td class="width"></td>
        <td class="width">证件号码：</td>
        <td class="width"></td>
      </tr>
      <tr>
        <td>准考证号：</td>
        <td></td>
        <td>报名科目：</td>
        <td></td>
      </tr>
      <tr class="titColor">
        <td colspan="3">科 目 名 称</td>
        <td colspan="1">成 绩</td>
      </tr>
      <tr class="center">
        <td colspan="3">《科目名称》</td>
        <td colspan="1">87</td>
      </tr>
      <tr class="center">
        <td colspan="3">《科目名称》</td>
        <td colspan="1">87</td>
      </tr>
      <tr class="center">
        <td colspan="3">《科目名称》</td>
        <td colspan="1">87</td>
      </tr>
    </table>
    <div class="btn pointer" @click="toReturn">返回</div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$bus.emit("navS", 5);
  },
  methods:{
      toReturn(){
          this.$router.go(-1)
      },
  }
};
</script>
<style lang="less" scoped>
.finger {
  margin-top: 20px;
}
.result-tit {
  text-align: center;
  color: #666a69;
  font-size: 20px;
  font-weight: bold;
  line-height: 3;
}
table {
  border-collapse: collapse;
  width: 80%;
  margin: 0 auto;
}

table,
td,
th {
  border: 1px solid #eeeeee;
  font-size: 14px;
  line-height: 2.5;
  padding: 5px 10px;
}
.titColor {
  color: #666a69;
  font-size: 16px;
  background: #eef8ff;
  text-align: center;
}
.center {
  text-align: center;
}
.width {
  width: 25%;
}
.btn {
  background: linear-gradient(to bottom, #d6edfe, #79c4fb);
  color: #005791;
  padding: 5px 25px;
  border: solid 1px;
  width: fit-content;
  margin: 30px auto 20px;
  font-size: 14px;
}
</style>